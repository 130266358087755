import React from "react"

const Projects = props => {
  return (
    <div className="ProjectGroup">
      <p>
        Coming Soon.
      </p>
    </div>
  )
}

export default Projects
