import React from "react"

const About = () => {
  return (
    <div>
      <p>
        I'm a Solutions Engineer by profession and Web & Mobile Developer by hobby. Currently, I work at <a href="developers.foursquare.com" target="_blank">Foursquare</a> helping developers build and scale amazing location experiences in their applications. When I'm not working, I enjoy running, tennis, photography and diving into new iOS or Web technologies.
      </p>
    </div>
  )
}

export default About
