import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import About from "../components/about"
import Projects from "../components/projects"
import staticdata from "../../staticdata.json"

const IndexPage = () => (
  <Layout>
    <div className="Hero">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Kyle A. Wiltshire</title>
        <link rel="canonical" href="https://www.kyleawiltshire.com" />
      </Helmet>
      <div className="HeroGroup">
        <img
          src={require("../images/profile_img.jpeg")}
          width="255"
          alt=""
        ></img>
        <h1>Kyle A. Wiltshire</h1>
        <p id="p1">Solutions Engineer @ Foursquare</p>
        <p id="p2">Web & Mobile Developer</p>
        <p id="p3">
          Human, <s>being</s> doing.
        </p>
        <div className="SocialImages">
          {staticdata.kyle.socialInfo.map(item => {
            return (
              <a
                target="_blank"
                href={item.url}
                rel="noopener noreferrer"
              >
                <img src={item.logo} width="30" alt=""></img>
              </a>
            )
          })}
        </div>

      </div>
    </div>
    <div className="AboutGroup">
      <div className="About">
        <h1 id="about">Hi there, I'm Kyle.</h1>
        <About />
      </div>
      <div className="Projects">
        <h1 id="projects">Here's what I've built</h1>
        <Projects />
      </div>
    </div>
  </Layout>
)

export default IndexPage
